<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Customer" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Proyek *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Proyek" v-model="data.proyek.nama" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalProyekActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="No. Identitas" v-model="data.no_identitas"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Gender</label>
            <v-select :options="genders" label="nama" :reduce="item => item.gender" :clearable="false" v-model="data.gender"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Tempat Lahir" v-model="data.tmp_lahir"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Tgl.Lahir</label>
            <flat-pickr class="w-full" v-model="data.tgl_lahir"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp *" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" label-placeholder="HP" v-model="data.hp"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label-placeholder="E-mail *" v-model="data.email"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Agama" v-model="data.agama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Kelurahan" v-model="data.kelurahan"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Kota" v-model="data.kota"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Kode Pos" v-model="data.pos"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Tujuan Pembelian" v-model="data.tujuan_pembelian"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Domisili" v-model="data.domisili"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat" v-model="data.alamat"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs">Tipe *</label>
            <vs-select autocomplete v-model="data.tipe" class="w-full">
              <vs-select-item v-for="(it, index) in ['Customer Rumah', 'Customer Afiliasi', 'Customer Other']" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Sumber Dana" v-model="data.sumber_dana"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Nikah" v-model="data.status_nikah"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Nama Pasangan" v-model="data.nama_pasangan"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Tempat Tinggal" v-model="data.status_tmptgl"/>
          </div>
        </div>

        <vs-divider> Pekerjaan </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Profesi</label>
            <v-select :options="profesis" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_profesi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Profesi" v-model="data.status_profesi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Jabatan" v-model="data.jabatan"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="NPWP" v-model="data.npwp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat NPWP" v-model="data.alamat_npwp"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat Surat" v-model="data.alamat_surat"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="No. Rekening" v-model="data.no_rekening"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Nama Instansi" v-model="data.nama_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp. Instansi" v-model="data.telp_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-printer" label-placeholder="Fax Instansi" v-model="data.fax_instansi"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat Instansi" v-model="data.alamat_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="CP Instansi" v-model="data.cp_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Jenis Usaha" v-model="data.jenis_usaha"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Penghasilan Tetap" v-model="data.pengh_tetap"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Penghasilan Tambahan" v-model="data.pengh_tambah"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Penghasilan Bulanan" v-model="data.pengh_bulanan"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Data Proyek"
                :active="isModalProyekActive"
                v-on:update:active="isModalProyekActive = $event">
        <Proyek :selectable="true" @selected="onSelectedModalProyek"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import CustomerRepository from '@/repositories/master/customer-repository'
import ProfesiRepository from '@/repositories/master/profesi-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import Proyek from '@/views/pages/master/proyek/Proyek'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  name: 'CustomerAdd',
  components: {
    Proyek,
    flatPickr,
    ValidationErrors,
    'v-select': vSelect
  },
  props: ['isActive'],
  mounted () {
    this.getAllProfesi()
  },
  data () {
    return {
      isModalProyekActive: false,
      isLoading: false,
      errors: null,
      data: {
        proyek: {},
        tipe_customer: 'master',
        tipe: 'Customer Rumah'
      },
      genders: [
        {
          gender: 'L',
          nama: 'Laki-laki'
        },
        {
          gender: 'P',
          nama: 'Perempuan'
        }
      ],
      profesis: []
    }
  },

  watch: {
    'data.proyek' (value) {
      this.data.id_proyek = value.id
    }
  },

  methods: {
    onSelectedModalProyek (item) {
      this.data.proyek = item
      this.isModalProyekActive = false
    },

    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.profesis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      CustomerRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
